<!-- <app-login></app-login> -->
<!-- <div class="main login-outer1" *ngIf="!loginDisplay">

  <div class="login-container">
    <div class="login-logo"><img src="../assets/images/logo-white.png"></div>
    <div class="login-txt">

      <span>Welcome to Our Insurance System. </span>
      <h1>Login Your Account</h1>

      <p>Click on below button to login with your microsoft email address.</p>
      <div class="loginbtn" (click)="login()"><a title="" class="btn btn-yellow col-3">Get Started</a></div>
    </div>
    <p class="login-footer">{{this._userMessages.footerText}}</p>

  </div>
</div> -->
<app-loader></app-loader>
<router-outlet></router-outlet>
<!-- </div> -->
<!-- <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="sessionExpired" [breakpoints]="{'960px': '75vw'}"
  [style]="{width: '40vw'}" [draggable]="false" [resizable]="false" [styleClass]="_confirmBox.borderColor"
  *ngIf="_confirmBox">
  <p>Your Session is going to expire in next {{timerValue }} Seconds, Do you want to Continue?</p>
  <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
    <p-button (click)="logout()" label="No" styleClass="btn btn-outline-primary"></p-button>
    <p-button (click)="Continue()" label="Yes" styleClass="btn btn-primary"></p-button>
  </ng-template>
</p-dialog> -->