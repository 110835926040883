import {NgModule, APP_INITIALIZER, ErrorHandler} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from '../app/features/home/home.component';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service'
import {NgxMaskPipe} from 'ngx-mask';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCoffee, faUserFriends, faTasks, faCalendarAlt, faPhoneVolume, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { NgSelectModule } from '@ng-select/ng-select';
import 'reflect-metadata';
import { SharedModule } from './shared/shared.module';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { AdminComponent } from './features/admin/admin.component';
import { HttpInterceptors } from 'src/app/shared/interceptors/http.interceptor';
import { ButtonModule } from 'primeng/button';
import { TagsModule } from './features/tags/tags.module';
import { CarrierManagementModule } from './features/carrier-management/carrier-management.module';
import { CoreModule } from './core/core.module';
import { WhiteBoardModule } from './features/white-board/white-board.module';
import { UserManagementModule } from './features/user-management/user-management.module';
import { RecallsModule } from './features/recalls/recalls.module';
import { ProspectModule } from './features/prospect/prospect.module';
import { ListManagementModule } from './features/list-management/list-management.module';
import { ContactModule } from './features/contact/contact.module';
import { BnNgIdleService } from 'bn-ng-idle';
import { DialogModule } from 'primeng/dialog';
import { TableauModule } from 'ngx-tableau';
import { AppointmentModule } from './features/appointment/appointment.module';
import { UnauthorizedComponent } from './features/unauthorized/unauthorized.component';
import { ViewProposalComponent } from './view-proposal/view-proposal.component';
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import "../../kendo-ui-license.js";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: "https://login.microsoftonline.com/b353c2b1-9191-4912-9d7c-1111dabc5dc0",
      redirectUri: environment.redirectUri,
      navigateToLoginRequestUrl: false
      // postLogoutRedirectUri: '/'
    },
    cache: {
      // cacheLocation: BrowserCacheLocation.LocalStorage,
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set('https://caffeinelamb-dev.azurewebsites.net/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://caffeinelamb-qa.azurewebsites.net/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://caffeinelamb-uat.azurewebsites.net/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://caffeinelamb.azurewebsites.net/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://caffeinelamb-tempuat.azurewebsites.net/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://gateway-caffeine-internal.azurewebsites.net/gateway/caffeine-internalqa/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://gateway-caffeine-internal.azurewebsites.net/gateway/caffeine-internal/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://gateway-caffeine-uat.azurewebsites.net/gateway/caffeine/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://gateway-caffeine.azurewebsites.net/gateway/caffeine/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  // protectedResourceMap.set('https://localhost:44358/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://gateway-caffeine-internal.azurewebsites.net/gateway/caffeine/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://gateway-caffeine-internal.azurewebsites.net/gateway/caffeine-internaldev/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://caffeinelambinternal-internalstaging.azurewebsites.net/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('https://gateway-caffeine-internal.azurewebsites.net/gateway/caffeine-internalstaging/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('http://localhost:5019/gateway/caffeine/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set('http://localhost:5001/', ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set(environment.BaseUrl, ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  protectedResourceMap.set(environment.apiUrl, ['api://21444384-a242-4939-a439-f8d0ce5daf6b/access_as_user']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}
@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        AdminComponent,
        UnauthorizedComponent,
        ViewProposalComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        UserManagementModule,
        RecallsModule,
        ProspectModule,
        ListManagementModule,
        ContactModule,
        WhiteBoardModule,
        NgbModule,
        DialogModule,
        SharedModule,
        NgSelectModule,
        FontAwesomeModule,
        SchedulerModule,
        TagsModule,
        ButtonModule,
        TableauModule,
        CarrierManagementModule,
        AppointmentModule,
        MsalModule], providers: [
        /* Changes start here. */
        BnNgIdleService,
        DatePipe,
        NgxMaskPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptors,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        Title,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faCoffee, faUserFriends, faTasks, faCalendarAlt, faPhoneVolume, faTimes, faCheck);
  }
}
