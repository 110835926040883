import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';
import {datadogRum, RumInitConfiguration} from "@datadog/browser-rum";
import {provideNgxMask} from "ngx-mask";
import {captureConsoleIntegration, dedupeIntegration} from "@sentry/angular";
// if (environment.production) {
//   enableProdMode();
// }
if (Object.keys(environment.dataDog).length > 0) {
  Sentry.init({
    environment: environment.environment,
    release: environment.appVersion,
    // release: `${environment.stage}@${environment.version}`,
    dsn: 'https://58ef6606bce9c65ebdfd284e116ba95e@o4507945433694208.ingest.us.sentry.io/4507945436381184',

    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      dedupeIntegration(),
      captureConsoleIntegration({ levels: ['error'] })
    ],
    tracePropagationTargets: ['localhost', environment.BaseUrl],
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    profilesSampleRate: 1.0
  });

  datadogRum.init(environment.dataDog as RumInitConfiguration);
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [provideNgxMask()]
})
  .catch(err => console.error(err));
